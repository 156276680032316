import React from "react";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaFax } from "react-icons/fa";
import { IoMailSharp } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";

const Footer = () => {
  return (
    <div className="bg-customNavbarTextColor">
      <div className="flex flex-row justify-around p-4">
        <div className="flex flex-row items-center gap-4">
          <FaPhoneVolume className="text-3xl text-white" />
          <div className="text-white text-xl font-bold">0232 444 80 80</div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <FaFax className="text-3xl text-white" />
          <div className="text-white text-xl font-bold">0232 832 00 06</div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <IoMailSharp className="text-3xl text-white" />
          <div className="text-white text-xl font-bold">
            belediyeiletisim@menemen.bel.tr
          </div>
        </div>
      </div>
      <div className="border-b-2 mx-10 border-[#989898]">

      </div>
      <div className="flex flex-row justify-around ">
        <div className="flex flex-col my-5">
          <div className="text-xl border-b-2 text-center text-white border-[#989898]">Menü</div>
          <div className="flex flex-col">
            <div className="flex flex-col mt-2 gap-1">
              <div className="flex flex-row items-center">
                <IoIosArrowForward className="text-white" />
                <div className="text-white">Tarihçe</div>
              </div>
              <div className="flex flex-row items-center">
                <IoIosArrowForward className="text-white" />
                <div className="text-white">Projeler</div>
              </div>
              <div className="flex flex-row items-center">
                <IoIosArrowForward  className="text-white"/>
                <div className="text-white">Basında Biz</div>
              </div>
              <div className="flex flex-row">
                <IoIosArrowForward className="text-white" />
                <div className="text-white">İletişim</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col my-5">
          <div className="text-xl border-b-2 text-center text-white border-[#989898]">
            Menemen
          </div>
          <div className="flex flex-col mt-2 gap-1">
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white" />
              <div className="text-white">Genel Bilgiler</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white" />
              <div className="text-white">Tarihçe</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward  className="text-white"/>
              <div className="text-white">Sağlık Hizmetleri</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward  className="text-white"/>
              <div className="text-white">Meclis Kararları</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white" />
              <div className="text-white">Stratejik Planlar</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white"/>
              <div className="text-white">Müdürlükler</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col my-5">
          <div className="text-xl border-b-2 text-center text-white border-[#989898]">
            Belediye
          </div>
          <div className="flex flex-col mt-2 gap-1">
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white" />
              <div className="text-white">Hizmetler</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white" />
              <div className="text-white">Vergi Takvimi</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward  className="text-white"/>
              <div className="text-white">İşkur Hizmet Noktası</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white" />
              <div className="text-white">İmar ve Şehircilik Müdürlüğü</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white" />
              <div className="text-white">Parklar Haritası</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
