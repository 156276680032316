import React, { useState, useEffect } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import {
  FaSquare,
  FaBuilding,
  FaRegObjectGroup,
  FaWhatsapp,
  FaHeartbeat,
  FaUsers,
  FaNewspaper,
  FaHistory,
  FaMapMarkedAlt,
} from "react-icons/fa";
import { MdElectricalServices, MdPublish } from "react-icons/md";

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hoverItemBalcova, setHoverItemBalcova] = useState(false);
  const [hoverItemKurumsal, setHoverItemKurumsal] = useState(false);
  const [hoverItemHizmetler, setHoverItemHizmetler] = useState(false);

  useEffect(() => {
    const imageInterval = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => clearInterval(imageInterval);
  }, [currentIndex]);

  const slides = [
    {
      url: "https://www.menemen.bel.tr/images/banner/gediz-nehri202241214364993.jpg",
      text: "Menemen için yorulmadan çalışacağız.",
    },
    {
      url: "https://www.menemen.bel.tr/images/banner/comlek-yapimi2019924101715289.jpg",
      text: "İkinci resim açıklaması",
    },
    {
      url: "https://www.menemen.bel.tr/images/banner/kubilay201999124621568.jpg",
      text: "Üçüncü resim açıklaması",
    },
  ];
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div>
      <div className="bg-gradient-to-r from-homeLayoutBackgroundColorFinish to-homeLayoutBackgroundColorBegin flex flex-row justify-center p-4 gap-20">
        <div className="pb-16 mt-7">
          <div className="mt-7">
            <img
              src={slides[currentIndex].url}
              className="w-[1440px] h-[700px] rounded-2xl object-cover duration-500 mt-7"
              alt="Slide"
            />
            <div className="absolute bottom-[10%] text-white font-semibold text-xl  ml-3 bg-homeImageTextContainer p-2 pr-28 rounded-md w-2/6 opacity-80">
              {slides[currentIndex].text}
            </div>
            <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactLeft onClick={prevSlide} size={30} />
            </div>
            <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactRight onClick={nextSlide} size={30} />
            </div>
            <div className="flex top-4 justify-start py-2 ">
              {slides.map((slide, slideIndex) => (
                <div
                  key={slideIndex}
                  onClick={() => goToSlide(slideIndex)}
                  className={`text-xl cursor-pointer ${
                    currentIndex === slideIndex ? "text-gray-500" : "text-white"
                  }`}
                >
                  <FaSquare />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-11 justify-center">
          <div
            className="relative p-2 rounded-md text-black bg-white text-center"
            onMouseEnter={() => setHoverItemBalcova(true)}
            onMouseLeave={() => setHoverItemBalcova(false)}
          >
            <p className="px-28 text-customNavbarTextColor font-semibold cursor-pointer hover:cursor-pointer">
              Menemen
            </p>
            {hoverItemBalcova && (
              <div className="absolute left-[-150px] mt-2 w-[440px] bg-hoverContainer rounded-md shadow-lg z-10">
                <div className="mt-2 border-b-2 p-1 text-lg text-customNavbarTextColor font-semibold border-slate-50">
                  Menemen
                </div>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col items-start p-4 gap-3">
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/38/tarihce"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Tarihçe
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/39/kubilay-olayi"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Kubilay Olayı
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/41/ilce-haritasi"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      İlçe Haritası
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/ListeSayfa/42/hastaneler"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Hastaneler
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/43/koylerimiz"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Köylerimiz
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/ImgListe/44/pazar-yerlerimiz"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Pazar Yerlerimiz
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/ImgListe/45/piknik-alanlarimiz"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Piknik Alanlarımız
                    </a>
                  </div>
                  <div className="flex flex-col items-start p-4 gap-3 border-slate-50">
                    <a
                      href="https://menemencocukoyunkoyu.com/"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Tematik Çocuk Oyun Köyü
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/ImgListe/116/menemen-dogal-guzellikleri"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Menemen Doğa Güzellikleri
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/ImgListe/9/menemen-tarihi-degerleri"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Menemen Tarihi Değerleri
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/3277/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      KVKK
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="relative p-2 rounded-md text-black bg-white text-center"
            onMouseEnter={() => setHoverItemKurumsal(true)}
            onMouseLeave={() => setHoverItemKurumsal(false)}
          >
            <p className="text-customNavbarTextColor font-semibold text-base cursor-pointer">
              Kurumsal
            </p>
            {hoverItemKurumsal && (
              <div className="absolute left-[-150px] mt-2 w-[470px] bg-hoverContainer rounded-md shadow-lg z-10">
                <div className="mt-2 border-b-2 p-1 text-lg text-customNavbarTextColor font-semibold border-slate-50">
                  Kurumsal
                </div>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col items-start p-4 gap-3">
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/151/misyon"
                      className="cursor-pointer font-semibold  transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Misyon Vizyon
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/4/ozgecmis"
                      className="cursor-pointer font-semibold  transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Başkan
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/49/mudurlukler"
                      className="cursor-pointer font-semibold  transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Müdürlükler
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/meclis-uye-listesi"
                      className="cursor-pointer font-semibold  transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Meclis Üyeleri
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/meclis-kararlari"
                      className="cursor-pointer font-semibold  transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Meclis Kararları
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/meclis-gundemleri"
                      className="cursor-pointer font-semibold  transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Meclis Gündemleri
                    </a>
                  </div>
                  <div className="flex flex-col items-start p-4 gap-3 ">
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/261/stratejik-plan-2020-2024"
                      className="cursor-pointer font-semibold  transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Stratejik Planlar
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/51/encumen-uyeleri"
                      className="cursor-pointer font-semibold  transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Encümen Üyeleri
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/99/muhtarliklar"
                      className="cursor-pointer font-semibold  transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Muhtarlıklar
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/6441/komisyon-uyeleri"
                      className="cursor-pointer font-semibold  transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Komisyon Üyeleri
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/209/performans-raporlari"
                      className="cursor-pointer font-semibold  transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Performans Raporları
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/6301/2022-faaliyet-raporu"
                      className="cursor-pointer font-semibold  transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Faaliyet Raporları
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="relative p-2 rounded-md text-black bg-white text-center"
            onMouseEnter={() => setHoverItemHizmetler(true)}
            onMouseLeave={() => setHoverItemHizmetler(false)}
          >
            <p className="text-customNavbarTextColor font-semibold text-base cursor-pointer">
              Hizmetlerimiz
            </p>
            {hoverItemHizmetler && (
              <div className="absolute left-[-150px] mt-2 w-[470px] bg-hoverContainer rounded-md shadow-lg z-10">
                <div className="mt-2 border-b-2 p-1 text-lg text-customNavbarTextColor font-semibold border-slate-50">
                  Hizmetlerimiz
                </div>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col items-start p-4 gap-3">
                    <a
                      href="https://www.menemen.bel.tr/ImgListe/138/sosyal-hizmetler"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Sosyal Hizmetler
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/ImgListe/90/sosyal-yardimlar"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Sosyal Yardımlar
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/87/saglik-hizmetleri"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Sağlık Hizmetleri
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/89/zabita-hizmetleri"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Zabıta Hizmetleri
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/81/evlendirme-memurlugu"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Evlendirme Memurluğu
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/6404/yapi-ruhsati-icin-gerekli-evrak-listesi"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      İmar Ve Şehircilik Müdürlüğü
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/173/iskur-hizmet-noktasi"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      İşkur Hizmet Noktası
                    </a>
                  </div>
                  <div className="flex flex-col items-start p-4 gap-3">
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/86/veterinerlik-hizmetleri"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Veterinerlik Hizmetleri
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/ImgListe/88/temizlik-hizmetleri"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Temizlik Hizmetleri
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/Sayfa/85/cenaze-defin-hizmetleri"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Cenaze Defin Hizmetleri
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/ImgListe/54/is-yeri-acma-ve-calistirma-ruhsati"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      İş Yeri Açma Ve Çalıştırma Ruhsatı
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/ImgListe/91/vergi-takvimi"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      Vergi Takvimi
                    </a>
                    <a
                      href="https://www.menemen.bel.tr/ImgListe/212/acil-toplanma-alanlariirap"
                      className="cursor-pointer font-semibold transition transform hover:text-teal-800 hover:translate-x-2 text-sm text-zinc-800"
                    >
                      ACİL TOPLANMA ALANLARI & İRAP
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className=" p-2 rounded-md text-black bg-white text-center">
            <a
              href="https://www.menemen.bel.tr/proje-arsivi"
              className="text-customNavbarTextColor font-semibold text-base cursor-pointer"
            >
              Projeler
            </a>
          </div>
          <div className=" p-2 rounded-md text-black bg-white text-center">
            <a
              href="https://www.menemen.bel.tr/etkinlik-takvimi"
              className="text-customNavbarTextColor font-semibold text-base cursor-pointer"
            >
              Etkinlikler
            </a>
          </div>
          <div className=" p-2 rounded-md text-black bg-white text-center">
            <a
              href="https://www.menemen.bel.tr/ImgListe/179/basinda-biz"
              className="text-customNavbarTextColor font-semibold text-base cursor-pointer"
            >
              Basında Biz
            </a>
          </div>
        </div>
      </div>
      <div className="bg-[#2d9dc6]">
        <div className="flex flex-row gap-7 mx-3 py-10">
          <a
            href="https://e-hizmet.menemen.bel.tr/#/home"
            className="flex flex-col border-2 p-7 items-center gap-7 bg-[#b5d19c] rounded-md flex-1 min-h-[200px] cursor-pointer"
          >
            <div className="text-center">E-Belediye</div>
            <FaBuilding fontSize={35} />
          </a>
          <a
            href="https://www.menemen.bel.tr/imarplanlari"
            className="flex flex-col border-2 p-7 items-center gap-7 bg-[#ffd4c1] rounded-md flex-1 cursor-pointer"
          >
            <div className="text-center">İmar Planları</div>
            <FaRegObjectGroup fontSize={35} />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=905524448008&text=Merhaba%20Menemen%20Belediyesi"
            className="flex flex-col border-2 p-7 items-center gap-7 bg-[#8a8d91] rounded-md flex-1 cursor-pointer"
          >
            <div className="text-center">Him & Whatsapp Hattı</div>
            <FaWhatsapp fontSize={35} />
          </a>
          <a
            href="https://www.menemen.bel.tr/menemen-nobetci-eczane"
            className="flex flex-col border-2 p-7 items-center gap-7 bg-[#e3f2fd] rounded-md flex-1 cursor-pointer"
          >
            <div className="text-center">Nöbetçi Eczane</div>
            <FaHeartbeat fontSize={35} />
          </a>
          <a
            href="https://www.menemen.bel.tr/ImgListe/212/acil-toplanma-alanlari"
            className="flex flex-col border-2 p-7 items-center gap-7 bg-[#f1f3f4] rounded-md flex-1 cursor-pointer"
          >
            <div className="text-center">Acil Toplanma Alanları & İrap</div>
            <FaUsers fontSize={35} />
          </a>
        </div>
      </div>
      <div className="bg-[#2d9dc6]">
        <div className="flex flex-row gap-7 mx-3 py-10">
          <a
            href="https://www.menemen.bel.tr/ihale-arsivi"
            className="flex flex-col border-2 p-7 items-center gap-7 bg-[#3f9db4] rounded-md flex-1 min-h-[200px] cursor-pointer"
          >
            <div className="text-center">İhaleler</div>
            <FaNewspaper fontSize={35} />
          </a>
          <a
            href="https://www.menemen.bel.tr/ImgListe/6/hizmetlerimiz"
            className="flex flex-col border-2 p-7 items-center gap-7 bg-[#8a8d91] rounded-md flex-1 cursor-pointer"
          >
            <div className="text-center">Sosyal Hizmetler</div>
            <MdElectricalServices fontSize={35} />
          </a>
          <a
            href="https://www.menemen.bel.tr/ImgListe/9/menemen-tarihi-degerleri"
            className="flex flex-col border-2 p-7 items-center gap-7 bg-[#2d9dc6] rounded-md flex-1 cursor-pointer"
          >
            <div className="text-center">Tarihi Değerler</div>
            <FaHistory fontSize={35} />
          </a>
          <a
            href="https://www.menemen.bel.tr/menemen-harita"
            className="flex flex-col border-2 p-7 items-center gap-7 bg-[#4cd9f8] rounded-md flex-1 cursor-pointer"
          >
            <div className="text-center">Menemen İlçe Haritası</div>
            <FaMapMarkedAlt fontSize={35} />
          </a>
          <a
            href="https://www.menemen.bel.tr/yayin-arsivi"
            className="flex flex-col border-2 p-7 items-center gap-7 bg-[#f1f3f4] rounded-md flex-1 cursor-pointer"
          >
            <div className="text-center">Yayınlar</div>
            <MdPublish fontSize={35} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
